
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import { Alert } from "ant-design-vue";
  import * as util from "@/util";
  import CommonProgress from "@/components/common/Progress.vue";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import CommonTab from "@/components/common/Tab.vue";
  import AttendanceTableMain from "@/components/attendance/table/Main.vue";

  @Component({
    components: {
      CommonProgress,
      CommonTab,
      AttendanceTableMain,
      AAlert: Alert,
    },
  })
  export default class Main extends Mixins(mixinAttendance) {
    get monthstr() {
      return util.formatDate(this.dateobj, "yyyy年mm月");
    }

    prev_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setDate(dateobj.getMonth() - 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
    next_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setDate(dateobj.getMonth() + 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
  }
