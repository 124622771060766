
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import AttendanceTableControl from "@/components/attendance/table/Control.vue";
  import AttendanceTableTable from "@/components/attendance/table/Table.vue";
  import AttendancePagerFoot from "@/components/attendance/pager/Foot.vue";

  @Component({
    components: {
      CommonProgress,
      AttendanceTableControl,
      AttendanceTableTable,
      AttendancePagerFoot,
    },
  })
  export default class Main extends Mixins(mixinAttendance) {}
