
  import { Component, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonSelect,
      CommonText,
    },
  })
  export default class Head extends Mixins(mixinAttendance) {
    disp_num_option = { 3: 3, 5: 5, 10: 10, 30: 30, 50: 50, 100: 100 };

    created() {
      const disp_num = Number(util.getLocalStorage("attendance_disp_num"));
      console.log(disp_num);
      this.disp_num = disp_num ? Number(disp_num) : 10;
      console.log(this.disp_num);
    }
  }
