
  import { Component, Mixins } from "vue-property-decorator";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonSelect,
    },
  })
  export default class Branchselector extends Mixins(mixinAttendance) {
    get branch_hash() {
      return this.global.branch_hash;
    }
  }
