
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";

  @Component({
    components: {},
  })
  export default class Row extends Mixins(mixinAttendance) {
    @Prop() each_attendance;
  }
