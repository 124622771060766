
  import { Component, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import CommonPager from "@/components/common/Pager.vue";

  @Component({
    components: {
      CommonPager,
    },
  })
  export default class Monthchanger extends Mixins(mixinAttendance) {
    get monthstr() {
      if (this.global)
        return util.formatDate(util.date2obj(this.payment_info.paid_on), "yyyy年mm月dd日");
      else return util.formatDate(this.dateobj, "yyyy年mm月");
    }

    prev_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setMonth(dateobj.getMonth() - 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
    next_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setMonth(dateobj.getMonth() + 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }

    get lock_type() {
      return this.viewing_branch == 0
        ? this.payment_info.lock_type
        : this.lock_types_hash[this.viewing_branch];
    }
  }
