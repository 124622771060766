
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendance from '@/mixins/mixinAttendance';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import AttendanceTableInputrow from "@/components/attendance/table/Inputrow.vue";
import AttendanceTableRow from "@/components/attendance/table/Row.vue";

@Component({
  components: {
    AttendanceTableInputrow,
    AttendanceTableRow,
  }
})
export default class Table extends Mixins(mixinAttendance) {
  @Watch('managed_each_attendances')
  log_update() {
    console.log('managed_each_attendances');
  }
}
