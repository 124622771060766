
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";

  import CommonText from "@/components/common/Text.vue";
  import CommonTime from "@/components/common/Time.vue";

  @Component({
    components: {
      CommonText,
      CommonTime,
    },
  })
  export default class Inputrow extends Mixins(mixinAttendance) {
    @Prop() each_attendance;

    get prescribed() {
      return this.m.prescribed[this.each_attendance.employee_id];
    }

    set prescribed(val) {
      this.m.setPrescribed({
        employee_id: this.each_attendance.employee_id,
        prescribed_object: val,
      });
    }

    get is_edit() {
      return this.m.edit_prescribed_flag;
    }
  }
