import { Component, Mixins } from "vue-property-decorator";
import monthly from "@/vuex/attendance/monthly";
import each_monthly from "@/vuex/attendance_each/monthly";
import global from "@/vuex/attendance/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/attendance/manager";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinAttendance extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get is_set_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.viewing_branch] &&
      monthly.data[this.date][this.viewing_branch].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.viewing_branch] &&
      monthly.data[this.date][this.viewing_branch].is_valid
    );
  }

  async get_global() {
    if (this.param_date == 0) await global.fetch({ yearmonth: null, auth: this.s_auth });
    else await global.fetch({ yearmonth: this.date, auth: this.s_auth });
  }
  async get_monthly() {
    await monthly.fetch({
      yearmonth: this.date,
      branch_id: this.viewing_branch,
      payment_info_id: this.global.payment_info.id,
    });
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data[this.date];
  }
  get monthly() {
    if (!this.is_set_global || !this.is_valid_global)
      this.get_global(); //get_monthlyでglobalを使用するため
    else if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.date][this.viewing_branch];
  }

  get payment_info() {
    return this.global.payment_info;
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = 0;
    }
    this.query = { viewing_branch: branch_id };
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    each_monthly.disable_all();
    this.query = { viewing_branch: val };
  }

  get tab() {
    var tab = this.query.attendance_tab;
    if (tab === undefined) {
      tab = "all";
      this.query = { attendance_tab: tab };
    }
    return tab;
  }
  set tab(val) {
    this.query_push = { attendance_tab: val };
  }

  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.date + "-01");
  }
  get date() {
    if (this.param_date == 0) {
      this.param_date = global.latest_date;
      return global.latest_date;
    } else {
      return this.param_date;
    }
  }
  set date(val) {
    this.param_date = val;
  }
  get param_date() {
    var date = this.query.attendance_date;
    if (date === undefined) {
      date = 0;
      this.query = { attendance_date: date };
    }
    return date;
  }
  set param_date(val) {
    if (val != this.param_date) {
      this.query_push = { attendance_date: val };
    }
  }

  get search_key() {
    var search_key = this.query.attendance_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { attendance_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { attendance_search_key: undefined };
    } else {
      this.query = { attendance_search_key: val };
    }
  }

  get sort_code() {
    return this.query.attendance_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_code: undefined };
    } else {
      this.query = { attendance_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.attendance_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_name: undefined };
    } else {
      this.query = { attendance_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.attendance_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_hiring_type: undefined };
    } else {
      this.query = { attendance_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.attendance_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_branch_id: undefined };
    } else {
      this.query = { attendance_sort_branch_id: val };
    }
  }
  get sort_base_day() {
    return this.query.attendance_sort_base_day;
  }
  set sort_base_day(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_base_day: undefined };
    } else {
      this.query = { attendance_sort_base_day: val };
    }
  }
  get sort_base_time() {
    return this.query.attendance_sort_base_time;
  }
  set sort_base_time(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_base_time: undefined };
    } else {
      this.query = { attendance_sort_base_time: val };
    }
  }
  get sort_prescribed_day() {
    return this.query.attendance_sort_prescribed_day;
  }
  set sort_prescribed_day(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_prescribed_day: undefined };
    } else {
      this.query = { attendance_sort_prescribed_day: val };
    }
  }
  get sort_prescribed_time() {
    return this.query.attendance_sort_prescribed_time;
  }
  set sort_prescribed_time(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_prescribed_time: undefined };
    } else {
      this.query = { attendance_sort_prescribed_time: val };
    }
  }
  get sort_lock_type() {
    return this.query.attendance_sort_lock_type;
  }
  set sort_lock_type(val) {
    if (val == "" || val == null) {
      this.query = { attendance_sort_lock_type: undefined };
    } else {
      this.query = { attendance_sort_lock_type: val };
    }
  }
  get page() {
    var page = this.query.attendance_page;
    if (page === undefined || page == "") {
      this.query = { attendance_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { attendance_page: undefined };
    } else {
      this.query = { attendance_page: val };
    }
  }

  get searched_sorted_each_attendances() {
    var each_attendances = util.deep_copy(this.each_attendances);
    each_attendances = this.search_each_attendances(each_attendances);
    each_attendances = this.sort_each_attendances(each_attendances);
    return each_attendances;
  }
  get managed_each_attendances() {
    var each_attendances = this.searched_sorted_each_attendances;
    each_attendances = this.page_each_attendances(each_attendances);
    return each_attendances;
  }
  search_each_attendances(each_attendances) {
    if (this.search_key === null) {
      return each_attendances;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return each_attendances.filter((each_attendance) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[each_attendance.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(this.employee_hash[each_attendance.employee_id].kana, key) ||
            (this.employee_hash[each_attendance.employee_id].code &&
              this.employee_hash[each_attendance.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_each_attendances(each_attendances) {
    if (this.sort_code)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_base_day)
      each_attendances.sort((x, y) => util.sort_idx(x.base_day, y.base_day, this.sort_base_day));
    else if (this.sort_base_time)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          x.inbase_time + x.outbase_time,
          y.inbase_time + y.outbase_time,
          this.sort_base_time,
        ),
      );
    else if (this.sort_prescribed_day)
      each_attendances.sort((x, y) =>
        util.sort_idx(x.prescribed_day, y.prescribed_day, this.sort_prescribed_day),
      );
    else if (this.sort_prescribed_time)
      each_attendances.sort((x, y) =>
        util.sort_idx(x.prescribed_time, y.prescribed_time, this.sort_prescribed_time),
      );
    else if (this.sort_lock_type)
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.lock_types_hash[this.employee_hash[x.employee_id].branch_id],
          this.lock_types_hash[this.employee_hash[y.employee_id].branch_id],
          this.sort_lock_type,
        ),
      );
    else {
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          "asc",
        ),
      );
      each_attendances.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          "asc",
        ),
      );
      if (this.viewing_branch == 0) {
        each_attendances.sort((x, y) =>
          util.sort_idx(
            this.employee_hash[x.employee_id].branch_id,
            this.employee_hash[y.employee_id].branch_id,
            "asc",
          ),
        );
      } else {
        each_attendances.sort((x, y) =>
          util.sort_idx(
            this.employee_hash[x.employee_id].branch_id == this.viewing_branch ? 0 : 1,
            this.employee_hash[y.employee_id].branch_id == this.viewing_branch ? 0 : 1,
            "asc",
          ),
        );
      }
    }
    return each_attendances;
  }
  page_each_attendances(each_attendances) {
    return each_attendances.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    console.log(sort_key, sort_mode);
    this.query = {
      attendance_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      attendance_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      attendance_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      attendance_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,
      attendance_sort_base_day: sort_key == "sort_base_day" ? sort_mode : undefined,
      attendance_sort_base_time: sort_key == "sort_base_time" ? sort_mode : undefined,
      attendance_sort_prescribed_day: sort_key == "sort_prescribed_day" ? sort_mode : undefined,
      attendance_sort_prescribed_time: sort_key == "sort_prescribed_time" ? sort_mode : undefined,
      attendance_sort_lock_type: sort_key == "sort_lock_type" ? sort_mode : undefined,
    };
  }

  get filter_type() {
    return this.query.attendance_filter_type;
  }
  set filter_type(val) {
    if (val == "" || val == null) {
      this.query = { attendance_filter_type: undefined };
    } else {
      this.query = { attendance_filter_type: val };
    }
  }
  get filter_value() {
    return this.query.attendance_filter_value;
  }
  set filter_value(val) {
    if (val == "" || val == null) {
      this.query = { attendance_filter_value: undefined };
    } else {
      this.query = { attendance_filter_value: val };
    }
  }
  get filter_range() {
    return this.query.attendance_filter_range;
  }
  set filter_range(val) {
    if (val == "" || val == null) {
      this.query = { attendance_filter_range: undefined };
    } else {
      this.query = { attendance_filter_range: val };
    }
  }

  get each_attendances_error() {
    return this.monthly.each_attendances.filter(
      (each_attendance) =>
        this.monthly.attendance_error.indexOf(each_attendance.employee_id) !== -1,
    );
  }
  get each_attendances_filter() {
    return this.monthly.each_attendances.filter((each_attendance) =>
      this.filter_each_attendance(each_attendance),
    );
  }

  filter_each_attendance(each_attendance) {
    if (
      this.filter_type === undefined ||
      this.filter_value === undefined ||
      this.filter_range === undefined
    ) {
      return false;
    } else {
      var value = each_attendance[util.attendance_filter_type_value[this.filter_type]];
      if (this.filter_type == 14) {
        value = this.sum_time(each_attendance);
      }
      if (this.filter_range == 0) {
        //以上
        return value >= this.filter_value;
      } else if (this.filter_range == 1) {
        //以下
        return value <= this.filter_value;
      } else if (this.filter_range == 2) {
        //より多い
        return value > this.filter_value;
      } else if (this.filter_range == 3) {
        //より少ない
        return value < this.filter_value;
      }
    }
  }

  sum_time(each_attendance) {
    let sum = Number(each_attendance.inbase_time) + Number(each_attendance.outbase_time);
    return sum;
  }

  get each_attendances() {
    if (this.tab == "all") {
      return this.monthly.each_attendances;
    } else if (this.tab == "error") {
      return this.each_attendances_error;
    } else if (this.tab == "filter") {
      return this.each_attendances_filter;
    }
  }

  get employee_hash() {
    return this.monthly.employee_hash;
  }
  get branch_hash() {
    return this.global.branch_hash;
  }
  get branch_hash_all() {
    return this.global.branch_hash_all;
  }
  get branch_hash_monthly_all() {
    return this.monthly.branch_hash_all;
  }
  get lock_types_hash() {
    return this.monthly.lock_types_hash;
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("attendance_disp_num", val);
    manager.setDispNum(val);
  }

  get lockable_attendance() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_attendance_lock == 1);
  }
  get editable_attendance() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_attendance == 2);
  }
}
