
  import { Component, Watch, Mixins } from "vue-property-decorator";
  import mixinAttendance from "@/mixins/mixinAttendance";
  import AttendanceTableBranchselector from "@/components/attendance/table/Branchselector.vue";
  import AttendancePagerHead from "@/components/attendance/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonTime from "@/components/common/Time.vue";

  @Component({
    components: {
      AttendanceTableBranchselector,
      AttendancePagerHead,
      CommonButton,
      CommonInfobutton,
      CommonSelect,
      CommonText,
      CommonTime,
    },
  })
  export default class Control extends Mixins(mixinAttendance) {
    attendance_detail_csv() {
      this.m.attendance_detail_csv({ branch_id: this.viewing_branch, yearmonth: this.date });
    }
    attendance_csv() {
      this.m.attendance_csv({ branch_id: this.viewing_branch, yearmonth: this.date });
    }
    attendance_pdf() {
      this.m.attendance_pdf({ branch_id: this.viewing_branch, yearmonth: this.date });
    }
    update_prescribed() {
      this.m.updatePrescribed({ branch_id: this.viewing_branch, yearmonth: this.date });
    }
    update_from_ask() {
      this.m.updateFromAsk({ yearmonth: this.date });
    }

    edit_prescribed() {
      this.each_attendances.forEach((each_attendance) => {
        if (this.lock_types_hash[this.employee_hash[each_attendance.employee_id].branch_id] == 0) {
          this.m.setPrescribed({
            employee_id: each_attendance.employee_id,
            prescribed_object: {
              prescribed_day: each_attendance.prescribed_day,
              prescribed_time: each_attendance.prescribed_time,
            },
          });
        }
      });

      this.m.editPrescribed();
    }

    lock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.lock({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
        close_ended_on: this.payment_info.close_ended_on,
      });
    }
    unlock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.unlock({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
      });
    }
    recalc() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.recalc({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
      });
    }

    get is_able_lock() {
      if (this.viewing_branch == 0) {
        return this.payment_info.lock_type == 0;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 0;
      }
    }
    get is_able_unlock() {
      if (this.viewing_branch == 0) {
        return this.payment_info.lock_type == 1;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 1;
      }
    }

    get is_able_calc() {
      if (this.viewing_branch == 0) {
        if (this.payment_info.lock_type == 0) {
          return 1; //勤怠ロックがされていない
        } else if (this.payment_info.lock_type == 2) {
          return 2; //給与ロックがされてしまっている
        } else if (this.global.is_each_payment_lock) {
          return 3; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      } else {
        if (this.lock_types_hash[this.viewing_branch] == 0) {
          return 1; //勤怠ロックがされていない
        } else if (this.lock_types_hash[this.viewing_branch] == 2) {
          return 2; //給与ロックがされてしまっている
        } else if (this.global.is_each_payment_lock) {
          return 3; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      }
    }

    @Watch("filter_type")
    onChangeFilterValue() {
      this.filter_value = null;
      this.filter_range = null;
    }
  }
