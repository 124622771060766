
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import AttendanceMonthchanger from "@/components/attendance/Monthchanger.vue";
import AttendanceMain from "@/components/attendance/Main.vue";

@Component({
  components: {
    AttendanceMonthchanger,
    AttendanceMain,
  }
})
export default class Attendance extends Mixins(utilMixins) {
}
